import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { DateTime } from 'luxon';
import { sortBy } from 'lodash';
import { getTimeEntry, addTimeEntry, updateTimeEntry, getTimeSettings } from 'src/resources/time-entries.resources.js';
import { getClient, getTeamMembers } from 'src/resources/clients.resources.js';
import { CpButton, CpLoader, CpModal, CprDatepicker } from 'canopy-styleguide!sofe';
import { successToast, warningToast } from 'toast-service!sofe';
import { handleError, customErrorMessages } from 'src/common/handle-error.helper';
import TimeEntryDuration from './time-entry-duration.component';
import TimeEntryDurationCalculator from './time-entry-duration-calculator.component';
import TimeClientSearch from './selectors/time-client-search.component';
import TimeEntryServiceSelector from './time-entry-service-selector.component';
import TimeEntryAssigneeSelector from './time-entry-assignee-selector.component';
import TimeTaskSearch from './selectors/time-task-search.component';
import TimeSubtaskSearch from './selectors/time-subtask-search.component';
import { roundToTheNearest } from './edit-time.helper.js';
import styles from './time-entry.styles.css';
import { hasAccess, useWithUserAndTenant } from 'cp-client-auth!sofe';
import { useServiceItemsIntegrations } from 'src/common/custom-hooks';
import { IntegrationsContext } from 'src/common/integrations/integrations-context';
import { featureEnabled } from 'feature-toggles!sofe';
import { tw } from 'src/common/classname-helpers';

export default function TimeEntryModal(props) {
  const [user] = useWithUserAndTenant();
  const integrationContext = useContext(IntegrationsContext);

  const { clientId, onClose, show, subtask, service, timeEntryId, task } = props;
  const [timeEntryLoaded, setTimeEntryLoaded] = useState(!timeEntryId);
  const [selectedDate, setSelectedDate] = useState();
  const [duration, setDuration] = useState(props.duration);
  const [durationCalculatorOn, setDurationCalculatorOn] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [clientLoaded, setClientLoaded] = useState(!timeEntryId);
  const [clientIdFromTimeEntry, setClientIdFromTimeEntry] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedServiceItem, setSelectedServiceItem] = useState(service);
  const [selectedAssignee, setSelectedAssignee] = useState(user);
  const [teamMembers, setTeamMembers] = useState([user]);
  const [loadingTeamMembers, setLoadingTeamMembers] = useState(true);
  const [selectedTask, setSelectedTask] = useState(task);
  const [selectedSubtask, setSelectedSubtask] = useState(subtask);
  const [note, setNote] = useState(props.note || '');
  const [billable, setBillable] = useState(true);
  const [invoiced, setInvoiced] = useState();
  const [locked, setLocked] = useState(false);
  const [initalLockedValue, setInitialLockedValue] = useState(false);
  const [autoSetBillable, setAutoSetBillable] = useState(true);
  const [lastUpdatedByString, setLastUpdatedByString] = useState('');
  const [lockedByString, setLockedByString] = useState('');
  const [saving, setSaving] = useState(false);
  const allowTasks = useMemo(() => hasAccess(user)('tasks_generic') || hasAccess(user)('tasks_notices'), [user]);
  const allowTeamTimeEdit = hasAccess(user)('time_edit_team');
  const allowPersonalTimeEdit = hasAccess(user)('time_edit_personal');
  const allowLockTimeEntry = hasAccess(user)('time_entries_locked');
  const readOnly = useMemo(
    () =>
      (timeEntryId && (selectedAssignee?.id === user.id ? !allowPersonalTimeEdit : !allowTeamTimeEdit)) ||
      props.disabled ||
      invoiced,
    [allowPersonalTimeEdit, allowTeamTimeEdit, invoiced, props.disabled, selectedAssignee?.id, timeEntryId, user.id]
  );
  const inputDisabled = useMemo(
    () => readOnly || (featureEnabled('toggle_gs_locked_time') && locked && !allowLockTimeEntry),
    [locked, readOnly, allowLockTimeEntry]
  );
  const [timeSettings, setTimeSettings] = useState([]);

  const itemsIntegrationHook = useServiceItemsIntegrations(
    !!integrationContext?.itemsIntegration?.allServiceItems || !!props.allServiceItems
  );
  const { categorizedServices, allServiceItems, qboCategories } = !!integrationContext?.itemsIntegration
    ?.allServiceItems
    ? integrationContext.itemsIntegration
    : !!props.allServiceItems
      ? props
      : itemsIntegrationHook;

  useEffect(() => {
    const durationCalculatorOn = user?.preferences?.duration === 'durationCalculator';
    setDurationCalculatorOn(durationCalculatorOn);
  }, [user]);

  useEffect(() => {
    if (!show) return;

    setAutoSetBillable(true);
    setBillable(!!clientId);
    if (!clientId) {
      setSelectedClient(null);
      setClientIdFromTimeEntry(null);
      setClientLoaded(!timeEntryId);
    }
    setSelectedTask(task);
    setSelectedSubtask(subtask);
    setSelectedServiceItem(service);
    setTimeEntryLoaded(!timeEntryId);
    setSelectedAssignee(user);
    setNote(props.note || '');
    featureEnabled('toggle_gs_locked_time') && setLastUpdatedByString('');
    featureEnabled('toggle_gs_locked_time') && setLockedByString('');
    featureEnabled('toggle_gs_locked_time') && setLocked(false);
    setSaving(false);
  }, [show]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'clientId', 'props.note', 'service', 'subtask', 'task', 'timeEntryId', and 'user'

  useEffect(() => {
    if (timeEntryId || !show) return;
    const roundedDuration = roundToTheNearest(props.duration || 1800, user?.preferences?.roundToNearest);
    setDuration(roundedDuration);
    const ended_time = props.endTime ? DateTime.fromISO(props.endTime) : DateTime.local();
    const started_time = ended_time.minus({ seconds: roundedDuration });
    setSelectedDate(started_time);
    setStartTime(durationCalculatorOn ? started_time : started_time.startOf('day'));
    setEndTime(durationCalculatorOn ? ended_time : started_time.startOf('day').plus({ seconds: roundedDuration }));
    setSelectedAssignee(user);
  }, [user, props.duration, props.endTime, show, durationCalculatorOn]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'timeEntryId'

  useEffect(() => {
    setTimeEntryLoaded(!timeEntryId);
  }, [timeEntryId]);

  useEffect(() => {
    setSelectedTask(task);
  }, [task]);

  useEffect(() => {
    setSelectedSubtask(subtask);
  }, [subtask]);

  useEffect(() => {
    if (timeEntryLoaded) return;
    let subscription;
    if (timeEntryId) {
      getTimeEntry(timeEntryId).subscribe(timeEntry => {
        const id = timeEntry?.relationships?.clients?.[0].id;
        id ? setClientIdFromTimeEntry(id) : setClientLoaded(true);
        const assignee = timeEntry.relationships?.participants?.[0];
        setBillable(timeEntry.billable);
        timeEntry.is_invoiced ? setInvoiced(true) : setInvoiced(false);
        setSelectedDate(DateTime.fromISO(timeEntry.started_at));
        setStartTime(DateTime.fromISO(timeEntry.started_at));
        setEndTime(DateTime.fromISO(timeEntry.ended_at));
        setDuration(timeEntry.duration);
        setSelectedServiceItem(timeEntry.relationships?.service_codes?.[0]);
        setSelectedTask(timeEntry.relationships?.tasks?.[0]);
        setSelectedSubtask(timeEntry.relationships?.subtasks?.[0]);
        setSelectedAssignee(assignee);
        if (timeEntry.description) {
          setNote(new DOMParser().parseFromString(timeEntry.description, 'text/html').body.textContent);
        }
        if (timeEntry.last_updated_by_name && timeEntry.last_updated_at) {
          setLastUpdatedByString(
            `Time entry was last edited by ${timeEntry.last_updated_by_name} on ${DateTime.fromISO(timeEntry.last_updated_at).toLocaleString(DateTime.DATE_SHORT)}`
          );
        }
        if (timeEntry.locked_by_name && timeEntry.locked_at) {
          setLockedByString(
            `Time entry was locked by ${timeEntry.locked_by_name} on ${DateTime.fromISO(timeEntry.locked_at).toLocaleString(DateTime.DATE_SHORT)}`
          );
        }
        if (timeEntry.unlocked_by_name && timeEntry.unlocked_at) {
          setLockedByString(
            `Time entry was unlocked by ${timeEntry.unlocked_by_name} on ${DateTime.fromISO(timeEntry.unlocked_at).toLocaleString(DateTime.DATE_SHORT)}`
          );
        }
        if (featureEnabled('toggle_gs_locked_time')) {
          setLocked(!!timeEntry.locked_at);
          setInitialLockedValue(!!timeEntry.locked_at);
        }
        setTimeEntryLoaded(true);
      }, handleError);
    }

    return () => {
      subscription && subscription.unsubscribe();
    };
  }, [timeEntryLoaded, show]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'timeEntryId'

  useEffect(() => {
    if (!(clientIdFromTimeEntry || clientId) || !show) return;
    const subscription = getClient(clientIdFromTimeEntry || clientId).subscribe(client => {
      if (client.is_active) {
        setSelectedClient(client);
      }
      setClientLoaded(true);
    }, handleError);
    return () => {
      subscription.unsubscribe();
    };
  }, [clientIdFromTimeEntry, clientId, timeEntryId, show]);

  useEffect(() => {
    setNote(props.note);
  }, [props.note]);

  useEffect(() => {
    if (!show) return;
    setLoadingTeamMembers(true);
    const subscription = getTeamMembers().subscribe(users => {
      const team = sortBy(
        users.filter(user => user.role === 'TeamMember'),
        ['name']
      );
      setTeamMembers(team);
      setLoadingTeamMembers(false);
    }, handleError);
    return () => {
      subscription.unsubscribe();
    };
  }, [show]);

  useEffect(() => {
    const subscription = getTimeSettings().subscribe(setTimeSettings);
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const onAfterClose = () => {
    setDuration(props.duration);
  };

  const onClientChanged = useCallback(
    client => {
      if (!selectedClient && !timeEntryId && autoSetBillable) {
        setBillable(true);
      }
      if (!client) {
        setBillable(false);
      }
      setSelectedClient(client);
      setSelectedTask(null);
      setSelectedSubtask(null);
    },
    [autoSetBillable, selectedClient, timeEntryId]
  );

  const onDateChange = useCallback(
    event => {
      const newDate = event.detail ? DateTime.fromJSDate(new Date(event.detail)) : DateTime.local();
      const updatedStartTime = startTime.set({ year: newDate.year, month: newDate.month, day: newDate.day });
      setSelectedDate(newDate);
      setStartTime(updatedStartTime);
      setEndTime(updatedStartTime.plus({ seconds: duration }));
    },
    [startTime, duration]
  );

  const onDurationChange = useCallback(
    duration => {
      setDuration(duration);
      setEndTime(startTime.plus({ seconds: duration }));
    },
    [startTime]
  );

  const onStartTimeChanged = useCallback((startTime, endTime, duration) => {
    setStartTime(startTime);
    setEndTime(endTime);
    setDuration(duration);
  }, []);

  const onEndTimeChanged = useCallback((endTime, duration) => {
    setEndTime(endTime);
    setDuration(duration);
  }, []);

  const onServiceItemChange = useCallback(serviceItem => {
    setSelectedServiceItem(serviceItem);
  }, []);

  const onAssigneeChanged = useCallback(
    assignee => {
      setSelectedAssignee(assignee || user);
    },
    [user]
  );

  const onTaskChanged = useCallback(task => {
    setSelectedTask(task);
    setSelectedSubtask(null);
  }, []);

  const onSubtaskChanged = useCallback(subtask => {
    setSelectedSubtask(subtask);
  }, []);

  const onSaveTimeEntry = () => {
    setSaving(true);
    const saveData = {
      started_at: startTime.toUTC().toISO(),
      ended_at: endTime.toUTC().toISO(),
      duration: Math.round(duration),
      description: note,
      billable,
      relationships: {
        clients: selectedClient ? [{ id: selectedClient.id }] : [],
        service_codes: selectedServiceItem ? [{ id: selectedServiceItem.id }] : [],
        ...(allowTasks && {
          tasks: selectedTask ? [{ id: selectedTask.id }] : [],
          subtasks: selectedSubtask ? [{ id: selectedSubtask.id }] : [],
        }),
        participants: selectedAssignee ? [{ id: selectedAssignee.id }] : [],
      },
      ...(featureEnabled('toggle_gs_locked_time') && allowLockTimeEntry && initalLockedValue !== locked
        ? { locked }
        : {}),
    };

    const getToastSuccessMessage = () => {
      const successString = `Time entry successfully ${timeEntryId ? 'updated' : 'created'}`;

      let lockedString = '';
      if (!timeEntryId && saveData.locked) {
        lockedString = ' and locked';
      }
      if (timeEntryId && initalLockedValue !== locked) {
        lockedString = saveData.locked ? ' and locked' : ' and unlocked';
      }

      return featureEnabled('toggle_gs_locked_time') ? successString + lockedString : successString;
    };

    const apiCall = timeEntryId ? updateTimeEntry : addTimeEntry;
    apiCall(timeEntryId ? { ...{ id: timeEntryId }, ...saveData } : saveData).subscribe(
      () => {
        successToast(getToastSuccessMessage());
        setSaving(false);
        window.dispatchEvent(
          new CustomEvent('billing-ui::time-saved', {
            detail: {
              task: selectedSubtask || selectedTask,
              clientId: selectedClient?.id,
              serviceItemIds: selectedServiceItem?.id ? [String(selectedServiceItem.id)] : [],
            },
          })
        );
        onClose(true);
      },
      e => {
        const toastMessage =
          customErrorMessages[e.data.errors.message] ||
          'An error occurred while saving the time entry. Please try again.';
        warningToast(toastMessage);
        handleError(e, { showToast: false });
        setSaving(false);
      }
    );
  };

  const handleClose = () => {
    onClose(false);
    setClientIdFromTimeEntry(null);
  };

  const requiredFieldsMissing =
    (timeSettings.includes('client') && !selectedClient) ||
    (timeSettings.includes('service_item') && !selectedServiceItem) ||
    (timeSettings.includes('task') && !selectedTask && allowTasks) ||
    (timeSettings.includes('subtask') && !selectedSubtask && allowTasks) ||
    (timeSettings.includes('note') && !note?.trim());

  return (
    <CpModal show={show} onClose={handleClose} onAfterClose={onAfterClose} width={500}>
      <CpModal.Header title={`${timeEntryId ? 'Edit' : 'Add'} time entry`}></CpModal.Header>
      <CpModal.Body>
        {!timeEntryLoaded || !clientLoaded ? (
          <CpLoader size="lg" />
        ) : (
          <>
            {featureEnabled('toggle_gs_locked_time') && (
              <div>
                {lastUpdatedByString && <p className={tw('my-0 last:mb-3 italic')}>{lastUpdatedByString}</p>}
                {lockedByString && <p className={tw('mt-2 first:mt-0 italic')}>{lockedByString}</p>}
              </div>
            )}
            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '16px' }}>
              <span>Billable</span>
              <label className="cps-toggle">
                <input
                  type="checkbox"
                  checked={billable}
                  disabled={!selectedClient?.id || inputDisabled}
                  onChange={() => {
                    setAutoSetBillable(false);
                    setBillable(!billable);
                  }}
                />
                <span />
              </label>
            </div>
            {featureEnabled('toggle_gs_locked_time') && (
              <div className={tw('flex items-center justify-between mb-4')}>
                <span>Lock Time Entry</span>
                <label className="cps-toggle">
                  <input
                    type="checkbox"
                    checked={locked}
                    disabled={!allowLockTimeEntry || invoiced}
                    onChange={() => setLocked(prev => !prev)}
                  />
                  <span />
                </label>
              </div>
            )}
            <hr />
            <div className="cp-mt-8" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className={`cps-form-group ${styles.editLabelAndInput}`}>
                <div className={styles.editLabel}>
                  <label htmlFor="dateInput">Date</label>
                  <span className="cps-color-primary">*</span>
                </div>
                <div className={styles.dateTimeInput}>
                  <CprDatepicker
                    id="dateInput"
                    date={selectedDate?.toISO()}
                    events={{
                      datechange: onDateChange,
                    }}
                    inputClass="cps-form-control"
                    disabled={inputDisabled}
                  />
                </div>
              </div>
              <TimeEntryDuration
                disableEdit={durationCalculatorOn || inputDisabled}
                duration={duration}
                key={show}
                onDurationChange={onDurationChange}
              />
            </div>
            {durationCalculatorOn && !inputDisabled && (
              <TimeEntryDurationCalculator
                endTime={endTime}
                onEndTimeChanged={onEndTimeChanged}
                startTime={startTime}
                onStartTimeChanged={onStartTimeChanged}
              />
            )}
            <div className={`${styles.editLabelAndInput} cps-form-group`}>
              <div className={`${styles.editLabel} cp-pt-4`} style={{ alignSelf: 'flex-start' }}>
                <label htmlFor="clientSelect">
                  Client
                  {timeSettings.includes('client') && <span className="cps-color-primary">*</span>}
                </label>
              </div>
              <div id="clientSelect">
                <TimeClientSearch client={selectedClient} onClientChanged={onClientChanged} disabled={inputDisabled} />
              </div>
            </div>
            <TimeEntryServiceSelector
              onServiceItemChange={onServiceItemChange}
              selectedServiceItem={selectedServiceItem}
              disableEdit={inputDisabled}
              categorizedServices={categorizedServices}
              allServiceItems={allServiceItems}
              qboCategories={qboCategories}
              required={timeSettings.includes('service_item')}
            />
            {allowTasks && (
              <>
                <div className={`${styles.editLabelAndInput} cps-form-group`}>
                  <div className={`${styles.editLabel} cp-pt-4`} style={{ alignSelf: 'flex-start' }}>
                    <label htmlFor="taskSelect">
                      Task
                      {timeSettings.includes('task') && <span className="cps-color-primary">*</span>}
                    </label>
                  </div>
                  <TimeTaskSearch
                    id="taskSelect"
                    client={selectedClient}
                    task={selectedTask}
                    onTaskChanged={onTaskChanged}
                    disabled={inputDisabled}
                  />
                </div>
                <div className={`${styles.editLabelAndInput} cps-form-group`}>
                  <div className={`${styles.editLabel} cp-pt-4`} style={{ alignSelf: 'flex-start' }}>
                    <label htmlFor="subtaskSelect">
                      Subtask
                      {timeSettings.includes('subtask') && <span className="cps-color-primary">*</span>}
                    </label>
                  </div>
                  <TimeSubtaskSearch
                    id="subtaskSelect"
                    task={selectedTask}
                    subtask={selectedSubtask}
                    onSubtaskChanged={onSubtaskChanged}
                    disabled={inputDisabled}
                  />
                </div>
              </>
            )}
            <TimeEntryAssigneeSelector
              loading={loadingTeamMembers}
              onAssigneeChanged={onAssigneeChanged}
              selectedAssignee={selectedAssignee}
              teamMembers={teamMembers}
              disableEdit={inputDisabled || !allowTeamTimeEdit}
            />
            <div className={styles.editLabelAndInput}>
              <div className={`${styles.editLabel} cp-pt-4`} style={{ alignSelf: 'flex-start' }}>
                <label htmlFor="note">
                  Note
                  {timeSettings.includes('note') && <span className="cps-color-primary">*</span>}
                </label>
              </div>
              <textarea
                id="note"
                className="cps-form-control +no-resize"
                style={{ width: '468px', height: '100px' }}
                maxLength="500"
                placeholder="Add note"
                value={note}
                onChange={evt => setNote(evt.target.value)}
                disabled={inputDisabled}
              />
            </div>
          </>
        )}
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          btnType="primary"
          onClick={onSaveTimeEntry}
          disabled={
            duration <= 0 ||
            readOnly ||
            requiredFieldsMissing ||
            (featureEnabled('toggle_gs_locked_time') && locked && !allowLockTimeEntry) ||
            saving
          }
          className="cp-mr-16">
          {timeEntryId ? 'Update' : 'Save'}
        </CpButton>
        <CpButton btnType="flat" onClick={handleClose}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}
